/* App.css */
body{    background-color: black;}
.bsperson-nav-style{background-color: black;
  color: white;
  margin-left: 10px;
  padding: 20px;
  border: 4px solid black;
}
.bsperson-styles{    margin-top: 4px;}
.profile-icon {
  background-color: black;
  border-radius: 50%;

  color: white;
}
.nav-icon{  margin-right: 5px;
  overflow: visible;
  margin-right: .1em;
  height: unset;
  vertical-align: sub;
width:1.3em;}
  .user-dropdown-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .5em;
    height: unset;
    vertical-align: sub;
  }
.user-dropdown-toggle:focus {
  box-shadow: none;
  display:contents;
}

/* Style for the dropdown menu to align items in a vertical column */
/* Style for the dropdown menu to align items in a vertical column */
.dropdown-menu {
  position: absolute; /* Prevents affecting navbar layout */
  top: 50px; /* Adjust based on the navbar height */
  right: 0;
  z-index: 1050; /* Ensures it is above other elements */
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 200px; /* Fixed width for consistency */
}

.dropdown-item-style {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  border: none;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
}
.dropdown-item-style:hover {
  background-color: #f8f9fa;
  color: #000;
}


/* Close the dropdown when clicking outside */
.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998; /* Ensures it is below the dropdown */
  background: transparent;
}


/* Optional: Style the Dropdown Divider */
.dropdown-divider {
  border-top: 1px solid #e9ecef;
}

/* Optional: Add hover effect to the Profile link */
.drop-menu-link-style:hover {
  text-decoration: underlne;
}

.content-wrap {
  flex: 1; /* Allows the content area to expand and fill available space */
  padding-top: 50px; /* Adjust based on your navbar height */
  /* Remove any padding-bottom to avoid conflict with the footer */
}

/* src/Profile.css */
.profile-container {
  padding: 20px;
}

.profile-avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the wrapper takes at least the full viewport height */
}
/* General Navbar Styles */
.app-navbar {
  background-color: #222222; 
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure navbar is above other elements */
}
.top-nav-button{


  margin-right: 7px;
    gap: 10px;

}
.navbar-content {
  margin-left: 10px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 20px; 
}

/* Navbar Collapse and Nav Items */

.nav-link {
  padding: 0.5rem 1rem;
  background-color: #111;
  color: #fff;
  text-decoration: none;
  border: 1px solid #333;
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 0.9rem;
  font-weight: 550;
  font-family: Arial, sans-serif;

}
.nav-link:hover{background-color: #ff683c;text-decoration:none;cursor:pointer;}
.btn {
  font-size: 1rem;
  padding: 10px 15px;
}

.homebtnstyle {
  background-color: #fff;
}

/* Navbar Toggle (Custom Hamburger Icon) */
.navbar-toggler {
  border: none;
  background-color: transparent;
  display: none; /* Hidden by default */
  cursor: pointer;
  z-index: 1001; /* Above the overlay */
}

.navbar-toggler-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
}

.navbar-toggler-icon span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 2px;
}

/* Overlay Menu Styles */
/* Overlay Menu Styles */
.overlay {
  height: 100%;
  width: 0; /* Start closed */
  position: fixed;
  z-index: 1001; /* Above navbar */
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.95);
  overflow-x: hidden;
  transition: width 0.5s ease; /* Smooth open/close */
}

.overlay.open {
  width: 100%; /* Open overlay when menu is active */
}

.overlay-content {
  width: 100%;
  text-align: center;
  display: flex;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
}

.overlay-content a,
.overlay-content button {
  background-color: #111;
  color: #fff;
  text-decoration: none;
  border: 1px solid #333;
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 0.9rem;
  margin-bottom:32px;
  padding: 1.2rem 1rem !important;
  width: 85%;
}

.overlay-content a:hover,
.overlay-content button:hover {
  color: #f08252; /* Accent color on hover */
}

.overlay-content a:hover, .overlay-content button:hover {
  color: #f08252;
}

.closebtn {
  background-color: #444!important;
  font-size: 18px!important;
  cursor: pointer;
  margin-top: 80px;
  font-weight: 550;
  font-family: Arial, sans-serif;
}
.nav-item{color:white;}
/* Media Queries for Responsiveness */
@media screen and (max-width: 820px) {
  .navbar-toggler {
    display: block; /* Show hamburger on small screens */
  }
.bsperson-styles{display:none;}
  .navbar-collapse {
    display: none; /* Hide navbar items on small screens */
  }

  .overlay {
    display: block; /* Ensure overlay is usable on small screens */
  }


}

@media screen and (min-width: 820px) {
  .overlay {
    display: none!important; /* Hide overlay on larger screens */
  }
.profile-menu-display-overlay{display:none;}
  .navbar-collapse {
    display: flex !important; /* Show navbar items on larger screens */
  }

  .navbar-toggler {
    display: none; /* Hide hamburger on larger screens */
  }
}

/* Additional Styles (Maintain Existing Styles) */
.dndflow {
  display: flex;
  flex-direction: column; 
  z-index: 0; 
}



aside {
  width: 20%;
  padding: 10px;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
}

.controls {
  margin-bottom: 10px;
}

.dndnode {
  margin-bottom: 10px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: grab;
}

.reactflow-wrapper {
  height: auto; 
  min-height: 80vh; 
  z-index: 0; 
  background-color: #646464;

}

.marketplace-selector {
  align-content: space-evenly;
  text-align: center;
  margin-top: 20px;
}




.btn-info:hover {
  cursor: pointer;  
  background-color: #f08252;
  transform: translateY(-2px);
}

.btnnavstyle {    
  letter-spacing: normal;
  text-decoration: none;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin: 0em;
  padding-block: 1px;
  padding-inline: 6px;
  border-width: 2px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
}
.marketplace-node-name {
  font-size: 16px;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}
.marketplace-node-category {
  font-size: 14px;
  color: #888;
  margin: 0;
}
.marketplace-icon {
  font-size: 48px;

  text-align: center;
  color: black;
}
.marketplace-node-card {
  width: 120px;
  max-width: 100%;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
  transition: transform 0.3s;}

  marketplace-row {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 75%;
    justify-content: center;
}
.front-market-display{display:flex;justify-content:center;}

.board-detail-styling{margin:20px;}

.marketplace-list{margin:20px;}

@media (max-width: 576px) {
  .header-logo {
    height: 15px;
}
  }


  /* Footer.css */

.footer-wrapper {
  background-color: #222222; /* Dark background */
  padding: 5px 0;

  width: 100%;
  bottom: 0;
}

.footer-link {
  font-size: 1.1rem;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  text-align: right;
  color:white;
background-color: #000;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
    font-weight: 800;
    margin-left: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}
.footer-content {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;margin-right:30px;
}

/* Privacy.css */

.privacy-content {
  margin-top: 50px; /* Adjust as needed */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for readability */
  border-radius: 8px;
}
.privacy-text{text-align:left;padding:10px;}
.privacy-content h1 {
  margin-bottom: 20px;
  text-align: center;
}

.privacy-content p {
  font-size: 16px;
  line-height: 1.6;
}