/* LoggedIn.css */
.tools-input-style{margin-right:10px;    margin-top: 3px;}
.tools-input-title-style{font-weight: 600;
  font-size: 16px;}
ul.tools-list{
  list-style: none;
  margin-block: unset;
  padding-inline: unset;
}
li.ant-pagination-item { width: 36px;
  height: 36px;
  display: flex
;
  align-items: center;
  justify-content: center;
  border-radius: 50%!important;
  border: 1px solid #ddd!important;
  font-size: 18px;
  cursor: pointer;}
li.ant-pagination-item a{color:white!important;font-size:20px;}
li.ant-pagination-item-active{background-color: rgb(255, 255, 255)!important;}
li.ant-pagination-item-active a{color:rgb(0, 0, 0)!important;font-size:20px;}
.sidebar-heading{text-align:left;font-size: 20px;
}
.sidebar-categories{width:30%;margin-top:50px;}
.sidebar-categories li{      padding: 0px 0px 10px 18px;
  text-align:left;
  display:block;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  line-height: 1.5714285714285714;}
  .categories-list{ padding: 0px 10px 0px 0px;
    margin: 0px;}
    .class-buttons-workflow{display:flex;justify-content:space-between;padding-top:10px;}
.icon-container{    height: unset;padding:unset;}
.add-llm-button{width:90%;margin-bottom: 5px;}
button.add-llm-button{padding: 5px;width:100%;}
.connections-button{    width: 100%;
  padding: 2px 10px;
  background-color: #333;
  border-radius: 7px;
  color: white;}
.filter-modified{display:flex;}
.market-list-card{    cursor: pointer;
  border: 2px solid black;
  border-radius: 7px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px #000000d1;}
.import-page-style{padding:20px;}
.import-page-btn{    border: 2px solid black;
  border-radius: 7px;
  background-color: #e05d44;
  color: white;
  margin-right: 10px;}
  .import-workflow-edit-btn{ border-radius: 7px;
    border: 2px solid black;
    margin-right: 10px;}
  .market-navbar{justify-content: center;}
.checkbox-advanced {
  display: inline-block;
  float:left;
  margin-top: 8px;
}
.card-body{    display: flex;}
.workflow-icons{height:20px;margin-right:4px;display:inline-flex;    vertical-align: middle;}
.open-chat-button{    background: white;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 73%);
}
.form-check-label{display: inline-flex;
    margin-left: 5px;}
/* General Layout */
.custom-board-markets {
  font-size: 12px;
  display: inline-flex;
  margin-right: 5px;
  color: #272727;
  font-weight: 600;
  padding-left: 4px;}
.chat-box-style{    position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: white;
  border: 4px solid grey;
  border-radius: 10px;
}
/* Page Title */
.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loading-spinner {
  width: 3rem !important;
  height: 3rem !important;
  color: #007bff;
}
.total-board-number{    font-size: 40px;}
/* Alert */
.custom-alert {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: center;
}
.cards-list-box{    display: flex;
  width: 100%;
  justify-content: space-evenly;}
/* User Info Card */
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.total-boards-name{font-size: 24px;}
.total-boards-box{      color: white;
  width: 35%;
  align-content: center;
  border-radius: 10px;
  background-color: black;
  border: 3px solid white;
  margin: 3px;
}
.info-card {
  border-radius: 12px;
  width: -webkit-fill-available;
}
.board-actions {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
    padding: 0px 10px;
}

.board-list {    margin-right: 10px;}
.board-actions .btn {
  background-color: transparent;

  border: 1px solid #9e9e9e;
  transition: background-color 0.3s, transform 0.3s;
}
.board-actions .btn:hover {
  background-color: #000000;
  color: #ffffff;
  transform: translateY(-2px);
}
.info-box {
  font-size: 1.1rem;
  color: #555;
  text-align: left;
  padding: 0rem .5rem;
  width: 100%;
  display: flex
;
align-items: center;
  justify-content: space-between;
}

.info-box p {
  margin: 0rem;
}

.toggle-api-key-btn {
  color: #000000;
  text-decoration: none;
  border: none;
  background: none;
}

.toggle-api-key-btn:hover {
  color: #000000;
  text-decoration: none;
  border: none;
  background: none;
}

.edit-button-loggedin{padding: 5px;
  margin: unset;
  margin-left: 5px;
  border: unset;
  background-color: transparent;
  padding-block: 0px;
  padding-inline: 0px;}

/* Feature Cards */
.feature-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 82%);
  text-align: center;
  margin: 20px 0px;
  padding: 20px 10px;
  border: 1px solid;
  width: 215px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  color: #000000;
}

.feature-link {
  font-size: 1.1rem;
  color: #333;
  text-decoration: none;
  flex-grow: 1;
}

.feature-link:hover {
  color: #007bff;
  text-decoration: underline;
}

/* Chat Button */
.chat-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
.chat-toggle-btn:hover {
  background-color: #0056b3;
}
.document-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

/* Document Name */
.document-name {
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.categories-scroll-container::-webkit-scrollbar {
  width: 15px; /* Adjust the width of the scrollbar */
  
}
.categories-scroll-container{height: 240px;
  overflow-y: scroll;
  width: 90%;
  border: 2px solid black;
  padding: 5px;
  border-radius: 7px;}
.categories-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  
}
.LLM-Category-Styling{padding: 0px 0px 0px 10px;
  display: flex
;
  justify-content: space-between;
  align-items: center;}
.categories-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll thumb */
  border-radius: 4px;    /* Roundness of the scroll thumb */
  border: 2px solid #f1f1f1; /* Creates padding around thumb */
}

.categories-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color when hovering over the thumb */
  
}
.sidebar-node-search{width: 90%;margin-bottom: 5px;}
/* Document Info */
.document-info {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}
.close-chat{    position: absolute;
  width: 100%;}
  .open-chat{    position: unset;display:flex;
height:20px;    width: 100%;}
.hide-button{display:none;}
.show-button{display:block;}
/* Truncate Text for Document Name */
.document-name {
  display: inline-block;
  vertical-align: middle;
}
/* Open/Close Chevron Button on Far Right */
.chat-arrow-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  z-index: 1000;
}



.chat-toggle-btn {
  background: linear-gradient(45deg, #606060, #757575);
  margin-top: 20px;

  border-radius: 7px;
  font-size: 1rem;
  box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 73%);

  transition: background 0.3s, transform 0.3s;
  width: 100%;
}
.chat-toggle-btn svg{fill: white;
  margin: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.toggle-selection{    display: flex
  ;
      justify-content: center;
      margin-bottom: 10px;
      gap: 8px;}
/* Chat Widget */
.chat-widget {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 300px;
  max-height: 600px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-card {
  flex: 1;
  border: none;
  border-radius: 10px;
}

.chat-card .card-header {
  background-color: #007bff;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-card .card-body {
  background-color: #f1f1f1;
  color: #333333;
}


.market-filter-dropdown .btn{   
  border-radius: 7px;min-width: 95px;

}
/* Unique class for the Dropdown.Menu */
.market-filter-dropdown-menu {
  /* Ensure the dropdown menu appears above other elements */
  z-index: 100;
display:none;
  /* Optional: Add custom styles */
  /* Example: */
  /* background-color: #f8f9fa; */
}

/* Toggle API Key Button */
.toggle-api-key-btn {

  text-decoration: none;
  padding: 0;
  margin-left: 5px;
}
/* Unique class for the Close button inside the dropdown */
.market-filter-close-button {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

/* Optional: Style for the Close button hover effect */
.market-filter-close-button:hover {
  background-color: #f1f1f1;
  color:black;
}

/* Ensure that the dropdown menu is displayed properly */
.market-filter-dropdown-menu.show {
  display: block;border-radius: 7px;
}

/* Additional Custom Styles (Optional) */

/* Style for the Filter dropdown to have a fixed width */
.market-filter-dropdown-menu {
  min-width: 300px;
}

/* Style for the "Show All" and market checkboxes */
.market-filter-dropdown-menu .form-check {
  margin: 0.5rem 1rem;    text-align: left;
}

/* Style for the Close button */
.market-filter-close-button {
  margin: 0 auto;
  padding: 0.5rem;
  border: none;
  background-color: black;
  color: white;
  border-radius: 0px 0px 7px 7px;
  font-weight: bold;
}

/* Optional: Add a hover effect for better UX */
.market-filter-close-button:hover {
  background-color: #e9ecef;
}
.loggedout-header-text{font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: 0px;
}
/* Add spacing between input fields and buttons in the board card */
.custom-board-card .form-control {

  border-width: 1px;
  border-color: #a7a7a7;
  border-radius: 5px;
  margin-bottom: 0px;
  padding: 10px;
  margin-top: 5px;
  font-weight: 400;
}
.custom-board-card-title{  margin-bottom: 10px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;}
  .custom-text-section {
    flex: 1 1 auto;
    cursor: pointer;

}
.header-edit-board{font-size:1.25rem;}
.description-edit-board{    font-size: 1rem;font-family:-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif}
/* Ensure the board card does not shift when switching between modes */
.custom-board-card {
  transition: all 0.3s ease;
}

/* Adjust the z-index if necessary */
.custom-board-card {
  position: relative;
}
.login-body-format{color:black;}
  .loggedout-sub-text{    color: hsl(var(--ls-gray-100));
    font-size: 1.25rem;
    padding: 0px 10px;
    line-height: 1.875rem;
    letter-spacing: -.01em;}
    .icon-display {    height: 20px;
      width: 20px;
      margin-right: 5px;}
      a{text-decoration: none;}
      
.btn-login {
  background-color: #000;
  color: #fff;
  width: 200px;
  border-radius: 25px;
  border: none;
  padding: 12px;
  transition: background-color 0.3s, transform 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.gallery-content{max-width: 600px;
  width: 100%;
  background-color: #fefefe;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 73%);
  margin-top: 50px;

 
  padding-bottom:20px;
  text-align: center;
}
.btn-login:hover {
  background-color: #444;
  transform: translateY(-2px);
}
.user-access {
  font-size: 1.25rem;
  color: #9e9e9e;
}


.logged-out-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  text-align: center;
}


.hero {
  max-width: 500px;
margin-bottom:20px;
  background-color: #fff;
  border-radius: 16px;
width: 100%;
}

.header-bar {
  width: 100%;
  background-color: #2b2b2b;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
}


.logged-in-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.main-content {
  max-width: 800px;
  background-color: #fefefe;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 5px rgb(255 255 255 / 73%);
  margin-top: 20px;
  text-align: center;
  width: 95%;



}

h1 {
  font-size: 2.5rem;
  color: #333;

  font-weight: bold;
}

p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 30px;
}
.create-board-btn {
  background-color: #1a1a1a;
  color: #f5f5f5;
  border-radius: 7px;
  transition: background-color 0.3s, transform 0.3s;
  padding: 5px 10px;
  border: 3px solid #000000;
  margin-right: 5px;
}

.create-board-btn:hover {
  background-color: #f08252;
  transform: translateY(-2px);
  color: #fff;
  cursor: pointer;
}

.board-card {
  background-color: #fcfcfc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.edit-btn, .delete-btn {
  background-color: transparent;
  border: none;
  padding: 0;
}

.edit-btn:hover, .delete-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.board-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.icon-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon {
  font-size: 2rem;
  color: #666;
}
.action-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.board-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.board-description {
  font-size: 0.9rem;
  color: #777;
}

.board-card .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.3s;
  padding: 0;
  background-color: transparent;
  border: 2px solid; 
}

.board-card .btn:hover {
  opacity: 0.9;
}


.btn-outline-primary {
  color: #000;

}

.btn-outline-info {
  color: #000000;

}

.btn-outline-danger {
  color: #000000;

}

.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-danger:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}


.list-group-item {width:100%;}
.modal-title {
  font-size: 1.5rem;
  color: #333;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
}

a.feature-link:hover{text-decoration: none;}


.edit-board-modal .modal-content {
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1060 !important; 
}

.edit-board-modal .modal-dialog {
  z-index: 1060 !important; 
}


.modal-backdrop {
  position: fixed;
  inset: 0;
  z-index: 900; /* Ensure it's behind the modal */
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: all;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
width:500px;
}
.loggedin-tab-menu{max-width:800px;
width:100%;    margin-top: 20px;    display: block;
}
.loggedin-tab-menu ul{    display: flex
  ;
      list-style-type: unset;
      margin-block-start: unset;
      margin-block-end: unset;
      margin-inline-start: unset;
      justify-content: space-between;
      padding-inline-start: unset;}
.loggedin-tab-menu li.nav-item {display:contents;
}
.loggedin-tab-menu button.active{background-color: #eb7b55;}
.loggedin-tab-menu button{
  padding: 0.5rem 1rem;

  color: #fff;
  text-decoration: none;
  border: 1px solid #333;
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 0.9rem;
  font-weight: 550;
  font-family: Arial, sans-serif;
width:48%;
}
/* Responsive Adjustments */


.btn-close{display:none;}
button .navlink.active {
  background-color: orange;
}
.modal-content {
  max-width: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid black;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  box-shadow: 0 0 0 5px #888888a1;
}
.custom-modal-dialog {
  margin: 0px auto 0 auto; /* 50px top margin, auto left and right */
}

.modal-header .btn-close {
  /* Ensure the close button is visible and properly styled */
  margin-top: -10px; /* Adjust if necessary */
}
.modal-header {
  background-color: #000;
  border-bottom: 1px solid #dee2e6;
  padding: 20px;
  text-align: center;
}

.modal-title {
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: bold;
}


.modal-body {
  padding: 30px;
  background-color: #ffffff;
  color: #000;
}


.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 20px;
  display: flex;
  gap: 10px;
}

.sidebar-header-section-text{    color: #333;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 5px;}
.modal-footer .btn {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
}
.btn-outline-secondary{color:black;}

.btn-secondary {
  background-color: #000000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.back-button {
  border-radius: 5px;
  margin: 0 auto;
  position: absolute;
}

.short-description{    margin: 10px;}
.btn-primary:hover {
  background-color: #000000;
  color: white;
}
.btn-secondary:hover {
  background-color: #4d4d4d;
  transform: translateY(-2px);
}
button.tab-button{width:50%;height:40px;border-radius:10px;cursor:pointer;}
button.tab-button.active{background-color: #1677ff;   transition: left 0.3s ease; color:white;}
.custom-close-button {
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
}
.form-control{       width: 100%;
  margin-right: 10px;
  color: black;}
.custom-text-section p {  
  text-align: left;
margin-top: 2px;margin-bottom:0px;

margin-left: 10px;

 }
 .regenerate-button{padding: 0px;
  height: 35px;
  width: 100%;
  border-style: solid;
  border-radius: 5px;
  margin-top: 10px;}
.node-name{    font-size: 24px;
  margin-left: 10px;
  align-items: center;
}
.custom-board-card {

      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 8px;
      transition: transform 0.3s, box-shadow 0.3s;
      width: 100%;
      border: 2px solid #333;
      margin-bottom: 10px;

}

.custom-board-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.custom-icon-section {
  flex: 0 0 auto;
  margin-right:5px;
  display:inline-block;
}
.custom-icon-section svg {height:20px;width: auto;}
.custom-text-section {
  flex: 1 1 auto;
  cursor:pointer;
}

.custom-action-section {
  padding-right: 10px;
  display: block;
}
.scrollable-modal-body {
  max-height: 60vh; /* Adjust as needed */
  overflow-y: auto;
}
.custom-board-title {
  font-size: 1.25rem;
  font-weight: 400;
  color: #333;
  text-align: left;
  margin: 0px;
  cursor: pointer;
  justify-content: space-between;
  display: flex
; align-items: center;  
margin-right: 5px;
}

.custom-board-description {
  font-size: 1rem;
  color: #1e1e1e;
  text-align: left;
  cursor:pointer;
  margin-bottom:0rem;
}

.custom-icon {
  font-size: 2rem;
  color: #666;
  cursor:pointer;
}

/* Buttons */
.custom-edit-btn, .custom-delete-btn {
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 8px 0px;
}

.custom-edit-btn:hover, .custom-delete-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
div.tab-container{background-color: #c7c7c7;
  margin: 20px 0px 20px 0px;
  border-radius: 7px;
  box-shadow: 0px 0px 0px 5px rgb(180 180 180 / 73%);
  padding: 5px;
  margin-top: 20px;}
.custom-edit-btn svg, .custom-delete-btn svg {
  font-size: 1.2rem;
  color: #333;
}
.logged-in-board-header-left{    margin-left: 30px;}
/* Parent div styling */
.loggedin-board-header {
  display: flex;
  align-items: center;
  padding: 10px 0px 0px 0px;
  margin: 0px 14px 10px 14px;
  justify-content: space-between;
}

/* Left-aligned title styling */
.board-title-left {
  text-align: left;
  letter-spacing: -0.1rem;
  font-weight: 300;
  font-family: sans-serif;
  margin-left:30px;
  margin-bottom:10px;

}
.market-filter-dropdown .btn {
  border-radius: 7px;
  padding: 5px 10px;
  border: 3px solid #000000;
  margin-right: 5px;
  color:rgb(255, 255, 255);
  background-color:#1677ff;
}
/* Optional: Right-aligned dropdown styling */
.loggedin-board-header-right .form-select {
  border-radius: 7px;
  padding: 5px 10px;
  border: 3px solid #000000;
  margin-right: 5px;
  color: black;
  font-size: 16.5px;
}


  .page-title{    letter-spacing: -.1rem;
    font-weight: 100;
    font-family: sans-serif;
    margin-bottom: 0px;}

    .custom-board-dates{Font-size: 12px;
      font-weight: 400;
      display: flex
  ;margin: 5px 0px;
 }
      .custom-board-dates span {margin-right: 5px;
        align-items: center;
        display: flex
    ;
      }
.oauth-status-container{    padding-bottom: 10px;
  padding-left: 10px;padding-right:10px;}
  .form-control-sort-by{max-width:450px;margin-left:10px;font-size: 15px;
    padding: 5px;border-radius:5px;}

    .sort-link{    margin: 10px;border-radius:5px;}
    .categories-list li.active {
      padding: 8px 8px 8px 18px;
      margin-bottom: 8px;
      cursor: pointer;
      background-color: #333;
      color: white;
      border-radius: 20px;
    }
/* Tags Display */
.apiKeysModalDisplay{width:100%!important;max-width:800px;}
    .tags-container{text-align: left;font-size:10px;margin-top:0px;margin-left: 10px;}
    .tags-display{    border-style: solid;
      border-width: 1px;
      padding: 2px;
      margin: 2px;
      display: inline-block; }
.open-close-custom-llm{padding:0px;}
.arrow-close{top:15px;}
.open-close-custom-llm svg{width:25px;}

      .logoutbtntxt{margin-bottom:10px;}

      .category-name{margin-bottom:5px; text-align: left;  }

      .node-name{font-size:24px;}


      .api-key-container, .api-key-display-group{    padding-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px;  }
      .pagination{margin:20px 0px;    justify-content: center;
        display: flex
    ;
     }
      .pagination button{margin: 0px 5px;     border-radius: 50%;
        border: 1px solid #000000;
        cursor:pointer;
        font-size: 18px;}
        .pagination .btn-primary {    background-color: #333;color:white;}
      .chatboxButton{    width: 90%;
        margin: 10px 0px;}
        .chatbox-input{display: flex;
          padding: 12px;
          border-top: 1px solid #d9d9d9;}
          .system-message {
            background-color: white;
            align-self: flex-end;
            text-align: left;
            margin-left: 10%;
            border-radius: 10px;
            padding: 10px;
        }
        .chatbox-messages, .chat-messages {
          flex: 1 1;
          overflow-y: auto;
          background-color: #b1b1b1;
          padding: 16px;
          padding-right: 10px;
      }
      .chatbox-message, .chat-message {
        margin-bottom: 12px;
        padding: 10px;
        border-radius: 10px;
        max-width: 90%;
        display: flex;
        flex-direction: column;
    }
    .chat-message p {
      margin: 5px;
      color: unset;
  }
  .message-text p {margin:5px;}
  .ant-list-split .ant-list-item{border-block-end:unset;padding: 5px 0px;cursor: pointer;}
  .ant-card .ant-card-body:hover{  transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);}
    .marketplace-sidebar:hover{ 
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);}
  /* General Styles */


/* Hero Image */
.hero-image {
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

/* Main Heading */
.main-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333333;
}

.sub-text {
  font-size: 1.2rem;
  color: #555555;
}

/* Login Button */
.login-button {
  padding: 10px 30px;
  font-size: 1rem;
}

/* Marketplace Section Header */
.marketplace-section {
  margin-top: 40px;
}

.marketplace-header {
  font-size: 2rem;
  font-weight: 600;
  color: #333333;
}

/* Marketplace List */
.marketplace-list {
  margin-top: 20px;
}

/* Marketplace Column */
.marketplace-col {
  display: flex;
  justify-content: center;
}

/* Marketplace Link */
.marketplace-link {
  width: 100%;
}

/* Marketplace Card */
.marketplace-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  background-color: #ffffff;
  text-decoration: none;
  color: inherit;margin-bottom:10px;
}

.marketplace-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
}

/* Icon on the Left */
.marketplace-icon {
  margin-right: 16px;
  color: #151515; /* Primary color for icons */
  flex-shrink: 0; /* Prevent icon from shrinking */
}

/* Content in the Center */
.marketplace-content {
  flex-grow: 1; /* Take up remaining space */
}

.marketplace-title {
  margin-bottom: 4px;
  font-size: 1.75rem;
  font-weight: 500;
  color: #333333;
  text-align:left;
}

.marketplace-category {
  margin: 0;
  font-size: 1rem;
  color: #888888;
  text-align:left;
}

/* Arrow Icon on the Right */
.marketplace-arrow {
  margin-left: 16px;
  color: #888888;
  flex-shrink: 0; /* Prevent arrow from shrinking */
}

/* View All Button */
.view-all-button {
  padding: 10px 30px;
  font-size: 1rem;
}

.sharedboarddetailhtml{text-align:left;margin-left:20px;margin-right:20px;}
.import-shared-board{width: 90%;
  border-radius: 10px;}

  .shared-back-icon-button{width: 60px;
    display: inline-block;}
    .shared-board-title-text{     width: 100%;}


  .custom-board-title-sidebar{margin-bottom:0px;font-size: 18px;}
  .chat-board-name {
    letter-spacing: -.1rem;
    font-weight: 300;
    font-family: sans-serif;
    text-align: center;
    font-size: 30px;
    padding: 10px 0px 10px 0px;
}

.back-button-position{position:absolute};
@media (max-width: 992px) {
  .feature-card {
    padding: 1rem;
  }
  .chat-container-doc{width:95%;}
  .container{width:95%;}
}

@media (max-width: 768px) {
  
  .filter-modified{justify-content: space-between;
    width: 100%;}
  .create-workflow-btn{display:inline-block;width: 30%;}
  .toggle-selection{        display: inline-flex
    ;
            gap: 20px;
            float: right;
          }
  .create-board-btn{border: none;
    display: block;        width: 100%;}
  .class-buttons-workflow{display:block;}
  .trash-icon-workflows{width: 100%!important;}
  div.board-actions{display: inline-grid
    ;
        gap: 10px;
        justify-content: space-evenly;
        padding: 0px 0px;}
        
  .chat-widget {
    width: 90%;
    right: 5%;
  }
  
.action-col
  .feature-card {
    text-align: center;
  
  }
.market-filter-dropdown{width:50%;}
.market-filter-dropdown .btn{width:100%}
  .info-card {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
.modal{  width: 100%;
max-width: 100%;}
}
@media (max-width: 500px) {
  

  .board-title-left{display:none;}
  .loggedin-board-header{display:block;}
  .custom-icon-section{padding:0px;}
  .loggedin-board-header-right .form-select {
    margin-right: 0px;
    width: 100%;
}
.market-filter-dropdown {display:inline-flex;width: 30%;}
}
@media (max-width: 343px) {




.market-filter-dropdown {width:100%;}
}