/* src/ChatInterface.css */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-width: 500px;

  border-radius: 8px;

  overflow: hidden;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}
.tools-panel {
  position: absolute;
  top: 0;
  left: -350px; /* width of the panel */
  width: 350px;
  height: 100%;
  background: #fff;
  transition: left 0.3s ease;
  overflow: auto;
  border-right: 1px solid #ccc;
  z-index: 9999;
}

.tools-panel.open {
  left: 0;
}
div.message.tool{    background-color: #e2e3e5;
  text-align: left;}
.tools-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.tools-header button {
  margin-right: 10px;
}
.chat-container h2{margin:0,}

.tools-header button {
  margin-right: 10px;
}
.chatoverlay {
  position: fixed;
  z-index: 1000;
  width: -webkit-fill-available;
  margin-right: 20px;
}

.chatoverlay-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  height: 540px;
}
.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
  height: 440px;
}

.message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
}

.message.user {
  background-color: #d1e7dd;
  text-align: right;
}

.message.assistant {
  background-color: #e2e3e5;
  text-align: left;
}

.message.function {
  background-color: #e2e3e5;
  text-align: left;
}

.input-area {
  display: flex;
  border-top: 1px solid #ccc;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;
}

.input-area button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 10px;
}

.input-area button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

a {
  color: #0d6efd;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, p {
  margin: 5px 0;
}

/* Ant Design Modal Styles */
.Modal {
    /* Ant Design handles most styling internally, but you can add custom styles here if needed */
  }
  
  .Overlay {
    /* Ant Design also handles overlay styling, but you can customize if necessary */
  }
  
  /* Close Modal Button */
  .close-modal-button {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .close-modal-button:hover {
    background-color: #c82333;
  }
  
  /* View Details Button */
  .view-details-button {
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .view-details-button:hover {
    background-color: #0b5ed7;
  }
  
  /* Ensure preformatted text wraps appropriately */
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
  }

  /* In ChatInterface.css or a new CSS file imported by ChatInterface */
  .react-json-view {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #000000; /* Default text color */
    background-color: #ffffff; /* Background color */
  }
  
  .react-json-view .string-key {
    font-weight: bold; /* Make keys bold */
  }
  
  .react-json-view .value-number,
  .react-json-view .value-boolean {
    font-weight: bold; /* Make numbers and booleans bold */
    color: #000000; /* Ensure they are black */
  }
  
  /* Capitalize section names and add a colon */
  .react-json-view .property-name::after {
    content: ":";
    text-transform: capitalize;
  }
  
  /* Optional: Add spacing after the colon for better readability */
  .react-json-view .property-name {
    margin-right: 5px;
  }
  .object-content{margin: 10px 0px !important;}
  .object-key-val{padding:unset!important;border-left:unset!important;}
  .object-key{font-weight:700;font-family: sans-serif;}
  .string-value{color:#393939;     font-family: sans-serif;}

div.object-key-val{margin-left: 0px;
    
    padding: 10px;
  }
    .colon{display:none;}
    .brace{display:none;}


   .array-key {
       display: inline !important; /* Ensures only the "2" is displayed */
   }

div.pushed-content.object-container div.pushed-content.object-container div.object-content{border-bottom: 2px solid black;}